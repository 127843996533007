import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
} from "@material-ui/core";
import { GrClose } from "react-icons/gr";
import HttpClient, {
  HttpGetOutput,
  HttpMutationOutput,
} from "../../lib/HttpClient";
import { useSnackbarContext } from "../../contexts/Snackbar";
import utils from "../../Utils";
import ImageFileIcon from "../../img/image-file-icon.png";
import { ReactComponent as PDFIcon } from "../../img/icons/icon_pdf.svg";

interface UploadDialogInterface {
  closeFn: () => void;
  patientId: String;
  isFromAppointment: Boolean;
  appointmentId?: String;
}

export default function UploadReportDialog({
  closeFn,
  patientId,
  isFromAppointment,
  appointmentId,
}: UploadDialogInterface) {
  const userProfile = utils.retrieveUserProfile();
  const getReportUploadUrl =
    "/api/v1/users/profile/" + patientId + "/reports/" + userProfile.clinicId;
  const getReportUploadUrlFromAppointment =
    "/api/v1/users/reports?appointmentId=" + appointmentId;

  const getReportsUrl = "/api/v1/users/reports?appointmentId=" + appointmentId;

  let [show, setShow] = useState(true);
  let [pRFetching, setPRFetching] = useState(false);
  let [bSRFetching, setBSRFetching] = useState(false);
  let [isGetReportsFetching, setIsGetReportsFetching] = useState(false);

  let [uploadedReportsList, setUploadedReportsList] = useState<any>();

  const [refreshReports, setRefreshReports] = useState(false); // toggle to hangle reports list mutation after upload event

  const ReportListing = uploadedReportsList?.map(
    (report: {
      id: React.Key | null | undefined;
      fileFormat: string;
      url: string | undefined;
      name:
        | boolean
        | React.ReactChild
        | React.ReactFragment
        | React.ReactPortal
        | null
        | undefined;
    }) => (
      <div key={report.id}>
        <Grid item xs={5}>
          <Paper variant="outlined" className="p-1 m-1">
            <Grid container>
              <Grid item xs={1}>
                {report?.fileFormat === "png" ||
                report?.fileFormat === "jpg" ||
                report?.fileFormat === "jpeg" ? (
                  <img
                    style={{ width: "30px", height: "auto" }}
                    src={ImageFileIcon}
                    alt=""
                    className="img-fluid"
                  />
                ) : (
                  <PDFIcon />
                )}
              </Grid>
              <Grid item xs={10}>
                <a href={report.url} target="_blank">
                  <label className="">{report.name}</label>
                </a>
              </Grid>
              {/*<Grid item xs={4}><label className={classes.text}>date here</label></Grid>*/}
              <Grid item xs={1}></Grid>
            </Grid>
          </Paper>
        </Grid>
      </div>
    )
  );

  const { setSnackbar } = useSnackbarContext();

  const handleClose = () => {
    setShow(false);
    closeFn();
  };

  useEffect(() => {
    if (isFromAppointment) {
      fetchUploadedPatientsReports();
    }
  }, [refreshReports]);

  const fetchUploadedPatientsReports = () => {
    setIsGetReportsFetching(true);

    HttpClient.performGet(getReportsUrl)
      .then((response: HttpGetOutput<any>) => {
        setIsGetReportsFetching(false);
        if (!response?.body?.status?.success) {
          setSnackbar({
            message: response?.body?.status?.message,
            severity: "error",
          });
        } else {
          setUploadedReportsList(response?.body?.reports);
        }
      })
      .catch((e) => {
        setSnackbar({
          message: "Error occurred while uploading report. " + e.message,
          severity: "error",
        });
      });
  };

  const uploadPathologyReport = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setPRFetching(true);
    const file = e.target.files[0];
    const { type, name } = file;
    console.log(name);
    HttpClient.performPost(
      isFromAppointment
        ? getReportUploadUrlFromAppointment
        : getReportUploadUrl,
      {
        reportType: "Pathology",
        note: "NA",
        name: name,
      }
    )
      .then((response: HttpMutationOutput<any>) => {
        if (!response?.body?.status?.success) {
          setSnackbar({
            message: response?.body?.status?.message,
            severity: "error",
          });
        } else {
          const uploadUrl = response?.body?.file?.signedUrl;
          if (uploadUrl) {
            fetch(uploadUrl, {
              method: "PUT",
              headers: {
                "Content-Type": type,
                "Access-Control-Allow-Origin": "*",
                "Cross-Origin-Resource-Policy": "cross-origin",
              },
              body: file,
            })
              .then((response) => {
                console.log(response);
                setPRFetching(false);
                if (response?.ok) {
                  setSnackbar({
                    message: "Pathology report uploaded",
                    severity: "success",
                  });
                  setRefreshReports((value) => !value);
                }
              })
              .catch((e) => {
                setSnackbar({
                  message: "Failed to upload report",
                  severity: "error",
                });
              });
          } else {
            setSnackbar({
              message: "Upload url not found",
              severity: "error",
            });
          }
        }
      })
      .catch((e) => {
        setSnackbar({
          message: "Error occurred while uploading report. " + e.message,
          severity: "error",
        });
      });
  };

  const uploadBodyScanReport = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setBSRFetching(true);
    const file = e.target.files[0];
    const { type, name } = file;
    console.log(name);
    HttpClient.performPost(
      isFromAppointment
        ? getReportUploadUrlFromAppointment
        : getReportUploadUrl,
      {
        reportType: "Bodyscan",
        note: "NA",
        name: name,
      }
    )
      .then((response: HttpMutationOutput<any>) => {
        if (!response?.body?.status?.success) {
          setSnackbar({
            message: response?.body?.status?.message,
            severity: "error",
          });
        } else {
          const uploadUrl = response?.body?.file?.signedUrl;
          if (uploadUrl) {
            fetch(uploadUrl, {
              method: "PUT",
              headers: {
                "Content-Type": type,
                "Access-Control-Allow-Origin": "*",
                "Cross-Origin-Resource-Policy": "cross-origin",
              },
              body: file,
            })
              .then((response) => {
                console.log(response);
                setBSRFetching(false);
                if (response?.ok) {
                  setSnackbar({
                    message: "Body scan report uploaded",
                    severity: "success",
                  });
                  setRefreshReports((value) => !value);
                }
              })
              .catch((e) => {
                setSnackbar({
                  message: "Failed to upload report",
                  severity: "error",
                });
              });
          } else {
            setSnackbar({
              message: "Upload url not found",
              severity: "error",
            });
          }
        }
      })
      .catch((e) => {
        setSnackbar({
          message: "Error occurred while uploading report. " + e.message,
          severity: "error",
        });
      });
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Upload Reports
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <GrClose />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div className="upload_div">
            <div className="heading">Pathology</div>
            <div>
              <input
                type="file"
                id="pathologyReportFile"
                accept="application/pdf, image/png, image/jpeg, image/jpg"
                onChange={uploadPathologyReport}
                name="pathologyReportFile"
              />
            </div>
            <br />
            {pRFetching && <span>uploading...</span>}
            <hr />
          </div>
          <div className="upload_div">
            <div className="heading">Body Scan</div>
            <div>
              <input
                type="file"
                id="bodyScanReportFile"
                accept="application/pdf, image/png, image/jpeg, image/jpg"
                onChange={uploadBodyScanReport}
                name="bodyScanReportFile"
              />
            </div>
            <br />
            {bSRFetching && <span>uploading...</span>}
          </div>
          {isFromAppointment && (
            <div className="upload_div">
              <div className="heading">Uploaded Reports</div>
              {isGetReportsFetching ? (
                "fetching reports..."
              ) : (
                <div>{ReportListing}</div>
              )}
            </div>
          )}

          <div style={{ marginBottom: "1em" }}></div>
        </DialogContent>
      </Dialog>
    </>
  );
}
